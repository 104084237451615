import React from 'react';
import classnames from 'classnames';

const Message = ({ message, animate }) => {
  const content = message.content.replace(
    /(https?:\/\/[^\s]+[^\s.,])/g,
    '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
  );

  return (
    <div className={classnames('message', message.role, { typing: animate })}>
      <div className="message-content" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default Message;