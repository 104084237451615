import React from 'react';
import './Navbar.css';
import logo from '../parade_logo.png';

const Navbar = () => {

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <nav className="navbar">
      <a href="https://parade.ai/" target="_blank" rel="noopener noreferrer">
        <img className="logo" src={logo} alt="Logo" />
      </a>
      <div className="nav-title">BrokerGPT Demo</div>
      <button className="refresh-button" onClick={handleRefresh}>
        Refresh
      </button>
    </nav>
  );
};

export default Navbar;