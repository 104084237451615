import React, { Component } from 'react';

class SendMessageForm extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  state = {
    content: ''
  };

  handleChange = (e) => {
    this.setState({ content: e.target.value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.content)
    if (!this.state.content) {
      return
    }
    this.props.onSendMessage(this.state.content);
    this.setState({ content: '' });
  }

  render() {
    const { isLoading } = this.props;
    return (
      <form className="send-message-form" onSubmit={this.handleSubmit}>
        <input
          type="text"
          disabled={isLoading}
          value={this.state.content}
          onChange={this.handleChange}
          ref={this.inputRef}
          placeholder="Type your message and hit Enter"
        />
      </form>
    );
  }
}

export default SendMessageForm;