import './App.css';
import './ChatApp.css';
import Navbar from './components/Navbar';
import ChatContainer from './components/ChatContainer'; 
import React from 'react';


function App() {
  return (
    <div className="App">
      <Navbar />
      <ChatContainer />
    </div>
  );
}

export default App;
