import React, { useEffect, useRef } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import Message from './Message';
import './MessageList.css'; 

const MessageList = ({ messages, isLoading, streamingMessage }) => {
  const messageListRef = useRef(null);

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <ScrollToBottom className="message-list">
      {messages.length === 0 && <div className="demo-prompt">I am a virtual carrier sales rep assistant. AMA! e.g. "Do you have van loads tomorrow?"</div>}
      {messages.map((message, index) => (
        <Message key={index} message={message} />
      ))}
      {streamingMessage && <Message message={streamingMessage} />}
    </ScrollToBottom>
  );
}

export default MessageList;