import React, { useCallback, useEffect, useState } from 'react';
import { get, isEmpty, last } from 'lodash';
import axios from 'axios';

import MessageList from './MessageList';
import SendMessageForm from './SendMessageForm';
import Progressbar from './Progressbar';


const BASE_URL = 'https://gpt3-demo-nsut5wajpa-uc.a.run.app';
const ASK_URL = '/ask';
const ANSWER_URL = '/answer';

const ChatContainer = () => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [conversationId, setConversationId] = useState();
  const [streamingMessage, updateStreamingMessage] = useState();

  useEffect(() => {
    if (isEmpty(messages) || last(messages).role === 'assistant') return;

    axios({
      method: 'post',
      baseURL: BASE_URL,
      url: ASK_URL,
      data: messages,
    }).then((response) => {
      setConversationId(response.data.conversationId);
    }).catch((error) => {
      console.log(error);
    });
  }, [messages]);

  useEffect(() => {
    if (!conversationId) return;

    try {
      const conversation = new EventSource(`${BASE_URL}${ANSWER_URL}?conversationId=${conversationId}`);
      
      const safeClose = () => {
        if (conversation.readyState === EventSource.CLOSED) return;
        conversation.close();
        setLoading(false);
      };

      conversation.onopen = (ev) => {
        updateStreamingMessage({ role: 'assistant', content: '' });
      };

      conversation.onmessage = (ev) => {
        let data;
        try {
          data = JSON.parse(ev.data);
        } catch (error) {
          if (ev.data === '[DONE]') { // Streaming ended
            safeClose();
            return;
          }
          console.error(error);
        }

        const content = get(data, 'choices[0].delta.content');
        const finishReason = get(data, 'choices[0].finish_reason');
        
        if (finishReason) { // End of streaming
          let assistantReply;
          updateStreamingMessage((message) => {
            // Capture streamed response
            assistantReply = message;
            return null;
          });
          // Update messages with the assistant response
          setMessages(prev => [...prev, assistantReply]);
        }

        if (!content) return; // ignore, probably just metadata

        updateStreamingMessage((prev) => ({ ...prev, content: prev.content + content }));
      };

      conversation.onerror = (ev) => {
        console.error('Streaming Error', ev)
        safeClose();
      };

      return safeClose;
    } catch (error) {
      console.log(error);
    }
  }, [conversationId]);

  const onSendMessage = useCallback((content) => {
    setLoading(true);
    setMessages((prevMessages) => [...prevMessages, { role: 'user', content: content }]);
  }, []);

  return (
    <div className="chat-container">
      <Progressbar active={isLoading} />
      <MessageList {...{ messages, isLoading, streamingMessage } } />
      <SendMessageForm onSendMessage={onSendMessage} isLoading={isLoading} />
    </div>
  );
}

export default ChatContainer;
